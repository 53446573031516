import * as React from 'react';
import {useDispatch} from "react-redux";
import {DataGridPro, useGridSelector, useGridApiContext, gridDetailPanelExpandedRowsContentCacheSelector,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,} from "@mui/x-data-grid-pro";
import {currencyFormatter} from "../../../../../Setup/Config";
import Toastr from "../../../../../Components/Elements/Toastr";
import {FundExpandDetails} from "../Cards/FundExpandDetails";
import {IconButton} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import {fetchFundStatement} from "../../Redux/Action";
import {useEffect} from "react";

export const CombinedStatementTable = () => {

    const dispatch = useDispatch();
    const [response, setResponse] = React.useState({});
    const [panelHeights, setPanelHeights] = React.useState({});
    const [items, setItems] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {

        await fetchFundStatement({ dispatch, filters:{} }).then((res) => {
            setResponse(res);
            setItems(res.data?.data)
            setIsLoading(false);
        });
    }

    const columns = [
        {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            renderCell: (params) => (
                <CustomDetailPanelToggle id={params.id} value={params.value} row={params.row} />
            ),
        },
        { field: 'month', headerName: 'Month', headerAlign:'left', align:'left', type: 'month', flex:1, maxWidth:150,
            valueGetter: (params) => params.row.month + ' ' + params.row.year
        },
        { field: 'total_disbursement', headerName: 'Funded', type: 'number', flex:1, maxWidth:150,
            valueFormatter: ({ value }) => currencyFormatter.format(value)
        },
        { field: 'realized_receivable', headerName: 'Receipts', type: 'number', flex:1, maxWidth:200,
            valueFormatter: ({ value }) => currencyFormatter.format(value)
        },
        { field: 'outstanding_receivable', headerName: 'Outstanding', type: 'number', flex:1, maxWidth:200,
            valueFormatter: ({ value }) => currencyFormatter.format(value)
        },
    ];

    const setPanelHeight = React.useCallback((id, height) => {
        setPanelHeights((prev) => ({ ...prev, [id]: height }));
    }, []);

    const getDetailPanelContent = React.useCallback(
        ({ row }) => <FundExpandDetails data={row} setPanelHeight={(height) => setPanelHeight(row.id, height)} />,
        [setPanelHeight]
    );

    const getDetailPanelHeight = React.useCallback(
        (params) => panelHeights[params.id] || 200, // Default height if not yet calculated
        [panelHeights]
    );

    return (
            <>
                <DataGridPro
                    autoHeight
                    getRowId={(row) => row.id}
                    rows={items || []}
                    columns={columns}
                    sx={{ border:'none' }}
                    loading={isLoading}
                    getDetailPanelContent={getDetailPanelContent}
                    getDetailPanelHeight={getDetailPanelHeight}
                    hideFooter={true}
                />
                <Toastr response={response} />
            </>
    );
}

const CustomDetailPanelToggle = (props) => {

    const { id, value: isExpanded } = props;
    const apiRef = useGridApiContext();

    const contentCache = useGridSelector(
        apiRef,
        gridDetailPanelExpandedRowsContentCacheSelector,
    );

    const shouldShowToggle = true;//!['PENDING','VERIFIED'].includes(props.row.status);

    const hasDetail = React.isValidElement(contentCache[id]);

    return shouldShowToggle && (
        <IconButton
            size="small"
            tabIndex={-1}
            disabled={!hasDetail}
            aria-label={isExpanded ? 'Close' : 'Open'}
        >
            <ExpandMoreIcon
                sx={{
                    transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
                    transition: (theme) =>
                        theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                        }),
                }}
                fontSize="inherit"
            />
        </IconButton>
    );
}

CustomDetailPanelToggle.propTypes = {
    id: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    row: PropTypes.any.isRequired,
};
